
import { Component, Vue } from 'vue-property-decorator'
import CImage from '@/components/Image/CImage.vue'
import Alert from '@/components/Alert/Alert.vue'

@Component({ 
  components:{
    CImage, 
    Alert
  }
})
export default class Blank extends Vue {

}
